import React,{useState,useEffect} from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  withStyles,
  Slider,
  Theme,
  Grid,
  Typography,
  Button,
  FormHelperText,
} from '@material-ui/core'
import clsx from 'clsx'
import { Flex, Spinner } from "theme-ui";

import { Decimal, Decimalish,Percent, LQTYStake, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import { useLiquity } from '../../hooks/LiquityContext'
import { shortenAddress } from '../../utils/shortenAddress'
import RedditTextField from '../../components/new-ui/RedditTextField'
import OverShadomBox from '../../components/new-ui/OverShadomBox'
import SetDomain from './SetDomain'
import CustomizedFaq from '../../components/new-ui/CustomizedFaq'
import { useTranslation, Trans, Translation } from 'react-i18next'

import { Transaction, useMyTransactionState } from "../../components/Transaction";
import { TopBar } from '../../components/new-ui/TopBar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // background:
      //   'linear-gradient(198deg, rgba(155, 88, 243, 0.17) 0%, rgba(53, 78, 247, 0.06) 45%, rgba(255, 255, 255, 0) 100%)',
      // backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
      // [theme.breakpoints.up('lg')]: {
      //   paddingLeft: 238,
      // },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 48,
      },
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      // background: 'linear-gradient(198deg, rgba(155, 88, 243, 0.17) 0%, rgba(53, 78, 247, 0.06) 45%, rgba(255, 255, 255, 0) 100%)',
      boxShadow: 'rgb(0 0 0 / 14%) 0px 0px 30px 5px inset',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      padding:'12px',
      paddingBottom:'20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'normal',
      margin: '0',
    },
    back: {
      background: '#fff',
      overflow: 'hidden',
      // padding: '30px 20px',
      // height: '100px',
    },
    backf: {
      backgroundColor:'rgba(255, 255, 255, .52)',
      overflow: 'hidden',
    },
    box: {
      marginTop: '16px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      },
    },
    boxForm: {
      padding: '0 20px 40px',
    },
    title: {
      textAlign: 'center',
      padding: '16px 16px 10px',
      fontSize: '18px',
      fontWeight: 500,
      color: '#745DDF',
      lineHeight: '25px',
    },
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      fontSize: '12px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '14px'
    },
    grid: {
      [theme.breakpoints.up('xs')]: {
        maxWidth:'444px'
      },
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    marginSlider: {
      height: theme.spacing(3),
    },
    text: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#293147',
      lineHeight: '17px'
    }
  })
)

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    marginTop:'4px',
    paddingBottom:'8px'
  },
  thumb: {
    height: 20,
    width: 10,
    backgroundColor: 'rgba(6, 211, 148, 1)',
    borderRadius: '1px',
    border: '1px solid #FFFFFF',
    boxShadow: '0px 2px 4px 0px rgba(120, 137, 246, 0.28)',
    marginTop: -6,
    marginLeft: -5,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    backgroundColor:'rgba(6, 211, 148, 1)',
    height: 8,
    borderRadius: 4,
  },
  rail: {
    backgroundColor:'rgba(210, 214, 220, 1)',
    height: 8,
    borderRadius: 4,
  }
})(Slider);

type FrontendRegistrationActionProps = {
  kickbackRate: Decimal;
  onchangeStatus: () => void
};

const FrontendRegistrationAction: React.FC<FrontendRegistrationActionProps> = ({ onchangeStatus, kickbackRate }) => {
  const {
    liquity: { send: liquity },
    account
  } = useLiquity();
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  // console.log(111,useLiquity(),liquity)

  const myTransactionId = "register-frontend";
  const myTransactionState = useMyTransactionState(myTransactionId);

  useEffect(() => {
    if (myTransactionState.type === "confirmed") {
      onchangeStatus()
    }
   }, [myTransactionState.type])
  
  return myTransactionState.type === "waitingForApproval" ? (
    <Flex>
      <Button
        variant="contained"
        disabled className={classes.subBtn}>
        <Spinner sx={{ mr: 2, color: "white" }} size="20px" />
        Waiting for your approval
      </Button>
    </Flex>
  ) : myTransactionState.type !== "waitingForConfirmation" &&
    myTransactionState.type !== "confirmed" ? (
    <Flex>
          <Transaction id={myTransactionId} send={liquity.registerFrontend.bind(liquity, kickbackRate, { from: account })}>
            <Button
              variant="contained"
              className={clsx(classes.subBtn,classes.btnColor)}
              style={{ width: '100%' }}>{t('text_48')}</Button>
      </Transaction>
    </Flex>
  ) : (<Button
      variant="contained"
      disabled className={classes.subBtn}>
      {t('text_48')}
    </Button>);
};

type state={
  ratio: string;
  idx: Number;
}

const selectFrontend = ({ ownFrontend }: LiquityStoreState) =>  ownFrontend ;

const FrontendRegistration: React.FC = ({
}) => {
  let { t, i18n } = useTranslation();
  const classes = useStyles()
  const { account } = useLiquity()

  const [value, setValue] = useState<state>({
    ratio: '10',
    idx: 0,
  })

  const [kickbackRate, setKickbackRate] = useState(Decimal.from(0.9));
  const [cut, setCut] = useState(Decimal.from(0.2));
  const frontend = useLiquitySelector(selectFrontend);

  const [slide, setSlide] = React.useState<number | string | Array<number | string>>(10);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const newKickbackRate = Decimal.from(newValue.toString()||0).div(100);
    const newCut = Decimal.ONE.sub(newKickbackRate);
    setKickbackRate(newCut);
    setSlide(newValue);
    setValue({
      ...value,
      ratio: newValue.toString(),
    })
    
  };
  
  useEffect(() => {
    if (frontend.status==='registered') {
      setValue({
        ratio:((1-Number(frontend.kickbackRate.toString(2)))*100).toString(),
        idx: 1
      })
    }
  },[frontend])
  
  const handleChange = (prop: any) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {

    setValue({ ...value, [prop]: e.target.value })

    try {
      const newKickbackRate = Decimal.from(e.target.value || 0).div(100);
      const newCut = Decimal.ONE.sub(newKickbackRate);

      setKickbackRate(newKickbackRate);
      setCut(newCut);
    } catch {}
  }

  return (
    <>
      <div className={classes.root}>
        <TopBar />
      
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <Box className={classes.content}>
              <Grid
                  container
                  spacing={3}
                  className={classes.box}
                  justify="center"
                >
                  <Grid item xs={12} className={classes.grid} justify="center">
                    <OverShadomBox className={classes.back}>
                        <Box className={classes.boxForm}>
                {
                  value.idx === 0 ? (
                    <>
                    <Typography variant="h3" className={classes.title}>
                    {t('text_06')}
                  </Typography>
                  <RedditTextField
                    labeltext={t('text_07')}
                    disabled
                    defaultValue={shortenAddress(account)}
                    className={clsx(classes.margin, classes.textField)}
                          variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ backgroundColor: '#fff', marginTop: '30px' }}
                  />
                  <RedditTextField
                    labeltext={t('text_08')}
                    disabled
                    coin="%"
                    onChange={handleChange}
                    value={value.ratio}
                    // defaultValue={value.ratio}
                    className={clsx(classes.margin, classes.textField)}
                    // defaultValue="react-reddit"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ backgroundColor: '#fff', marginTop: '20px' }}
                              />
                              
                  <PrettoSlider
                    onChange={handleSliderChange}
                    // valueLabelDisplay="auto"
                    // aria-label="pretto slider"
                                // aria-labelledby="input-slider"
                                min={0}
                                max={50}
                    aria-labelledby="continuous-slider"
                    value={typeof slide === 'number' ? slide : 0}
                     />

                    <Box component="div" display="flex" justifyContent="space-between" alignItems="center" >
                      <Typography variant="body2" className={classes.text}>
                        0%
                      </Typography>
                      <Typography variant="body2" className={classes.text}>
                        50%
                      </Typography>
                    </Box>
        
                      <FrontendRegistrationAction onchangeStatus={() => {setValue({ ...value, idx: 1 })}} {...{ kickbackRate }}/>

                  <FormHelperText
                    component="div"
                    style={{ marginTop: '20px' }}
                    className={classes.helperText}
                  >
                    {t('text_09')}
                  </FormHelperText>
                  <FormHelperText
                    component="div"
                    style={{ marginTop: '10px' }}
                    className={classes.helperText}
                            >
                              {t('text_62')}
                                {/* You are about to register  */}
                      <strong style={{margin:'0 2px'}}>{shortenAddress(account)}</strong>
                      {t('text_63')}
                      <strong style={{margin:'0 2px'}}>{value.ratio}% </strong>
                              {t('text_64')}
                              {/* <br />
                              {t('text_65')}
                              <br />
                              {t('text_66')} */}
                  </FormHelperText>
                      </>
                  ): (
                      <>
                        <SetDomain ratio={value.ratio}></SetDomain>
                      </>
                )}
              
              </Box>
                    </OverShadomBox>
                </Grid>
                <Grid item xs={12} className={classes.grid} style={{paddingBottom:'30px'}} justify="center">
                  <OverShadomBox className={classes.backf}>
                    <CustomizedFaq></CustomizedFaq>
                  </OverShadomBox>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </>
  )
};
export default FrontendRegistration;
