import React,{useState,useEffect} from 'react'
// import clsx from 'clsx'
import {
  Box,
  AppBar,
  makeStyles,
  Theme,
  createStyles,
  Typography
} from '@material-ui/core'
import { Decimal, Decimalish,Percent, LQTYStake, LiquityStoreState } from "@liquity/lib-base";
import { useTranslation, Trans, Translation } from 'react-i18next'
import { useLiquity } from '../../hooks/LiquityContext'
import Api from '../../utils/http'
import common from '../../utils/common'
import logo from '../../assets/logo.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h6: {
      marginLeft:'10px',
      fontSize: '20px',
      fontWeight: 600,
      color: '#293147',
      lineHeight: '24px'
    },
    p: {
      marginTop:'20px',
      marginBottom:'10px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '17px'
    }
  })
)

type ValueType = {
  account: string;
}

export const AgentEarnings: React.FC<ValueType> = ({account}) => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();

  const [lqtyValue, setLqtyValue] = useState<string>('0.00')
  const [lusdValue, setLusdValue] = useState<string>('0.00')

  const handleJudgmentInit = () => {
    const params = {
      address: account
      // address:'0x47a7d15b7452820dd7a565ea9c39d8b6cef51ed7'
    }
    Api.get_front_end_lqty_gain(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        setLqtyValue(Decimal.fromBigNumberString(data.value).toString(2))
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleLusdInit = () => {
    const params = {
      address: account
      // address:'0x47a7d15b7452820dd7a565ea9c39d8b6cef51ed7'
    }
    Api.user_front_end_contract(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        setLusdValue(Decimal.fromBigNumberString(data.value).toString(2))
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    handleJudgmentInit()
    handleLusdInit()
  },[])

  return (
    <>
      {/* 未分配收益 */}
        <Typography variant="subtitle1" className={classes.p}>
          {t('text_65')}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.h6} variant="h6">
                  {lqtyValue} LQTY
          </Typography>
        </Box>
      {/* 我的用户质押量 */}
        <Typography variant="subtitle1" className={classes.p}>
          {t('text_66')}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.h6} variant="h6">
                  {lusdValue} LUSD
          </Typography>
        </Box>
    </>
  )
}
