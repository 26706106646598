import React, { useState} from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  createStyles,
  withStyles,
  Button,
  Theme,
  Chip,
  MenuItem,
  MenuProps,
  ListItemText,
  Menu,
} from '@material-ui/core'

import { LiquityStoreState } from '@liquity/lib-base'
import { useLiquitySelector } from '@liquity/lib-react'

import { COIN, GT } from '../../strings'
import { useLiquity } from '../../hooks/LiquityContext'
import { shortenAddress } from '../../utils/shortenAddress'
import { useTranslation, Trans, Translation } from 'react-i18next'

import menu from '../../assets/menu.png'
import menu_01 from '../../assets/menu_01.png'
import logo from '../../assets/logo.png'
import icon_lang from '../../assets/icon-lang.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: '#fff',
      minHeight: '64px',
      paddingLeft: '10px',
      [theme.breakpoints.down('xs')]: {
        minHeight: '48px',
      },
    },
    margin: {
      '& > *': {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          margin: '0',
        },
      },
    },
    box: {
      boxShadow: 'none',
      zIndex:10000
    },
    btnBack: {
      background: 'linear-gradient(260deg, #92A2E5 0%, #9F85EB 100%)',
      borderRadius: '8px',
      fontSize: '12px',
      color: '#FFFFFF',
    },
    valueE: {
      background: 'rgba(239, 236, 255, 0.64)',
      borderRadius: '8px',
      paddingLeft: '10px',
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 1)',
      fontWeight: 400,
    },
    valueL: {
      background: '#E7E2FF',
      borderRadius: '8px',
      padding: '6px 10px',
      marginLeft: '10px',
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 1)',
      fontWeight: 600,
    },
    icon: {
      width: '16px',
      height: '16px',
      marginLeft: '4px',
      borderRadius: '50%',
      background:
        'linear-gradient(180deg, #E02020 0%, #FA6400 17%, #F7B500 33%, #6DD400 50%, #0091FF 67%, #6236FF 83%, #B620E0 100%)',
    },
    iconB: {
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        marginRight: '0',
        marginLeft:'-10px'
      },
    },
    menu: {
      width: '22px',
      height: '22px',
      [theme.breakpoints.down('xs')]: {
        width: '16px',
        height: '16px',
      },
    },
    logo: {
      height: '28px',
      [theme.breakpoints.down('xs')]: {
        height: '22px',
      },
    },
    lang: {
      height:'20px'
    },
    active: {  
      backgroundColor: 'rgba(227, 227, 243, .47)!important',
      color: '#545A6C'
    },
  })
)

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 8px 10px 0px rgba(120, 137, 246, 0.28)',
    borderRadius: '4px'
  },
})((props: MenuProps) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  )
});

const select = ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
})

const isLang = (str: string) => {
  if (str==='zh') {
    return '中文';
  } else {
    return 'EN';
  }
}

export const TopBar: React.FC = ({

}) => {
  const classes = useStyles()
  const { account } = useLiquity()

  const { accountBalance, lusdBalance, lqtyBalance } = useLiquitySelector(
    select
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  let { t, i18n } = useTranslation();
  const [language, setLanguage]= useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (str: string) => {
    i18n.changeLanguage(str);
    setLanguage(str);
    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.box}>
      <Toolbar className={classes.background}>
        {/* <LiquityLogo /> */}
        <Box flexGrow={1} display="flex" justifyContent="flex-start" alignItems="center">
          
           <Button
              component={RouterLink}
              to='/'>
          <img src={logo} className={classes.logo} alt=""/>
          </Button>
        </Box>

        <Box
          className={classes.margin}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Hidden smDown>
            <Button className={classes.btnBack}>
              {lusdBalance.prettify()} LUSD
            </Button>
            <Button className={classes.btnBack}>
              {lqtyBalance.prettify()} LQTY
            </Button>
          </Hidden>
          <Hidden xsDown>
            <Box
              component="span"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              className={classes.valueE}
              m={1}
            >
              {accountBalance.prettify()} ETH
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.valueL}
                p={1}
              >
                {shortenAddress(account)}
                <Box className={classes.icon}></Box>
              </Box>
              {/* <Box component="span" m={1}>0x05…05FA</Box> */}
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.valueL}
                p={1}
              >
                {shortenAddress(account)}
                <Box className={classes.icon}></Box>
            </Box>
          </Hidden>

          <Button
            style={{margin: '8px 0 8px 8px',minWidth:'auto'}}
            startIcon={<img src={icon_lang} className={classes.lang }/>}
                  onClick={handleClick}
              >
            <Hidden xsDown>{isLang(i18n.language)}</Hidden>
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={()=>handleSelect('en')} selected={isLang(i18n.language)==='EN'} className={isLang(i18n.language)==='EN'? classes.active:''}>
              <ListItemText primary="EN" />
            </MenuItem>
            <MenuItem onClick={()=>handleSelect('zh')} selected={isLang(i18n.language)==='中文'}  className={isLang(i18n.language)==='中文'? classes.active:''}>
              <ListItemText primary="中文" />
            </MenuItem>
          </StyledMenu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
