import React,{useState,useEffect} from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  Grid,
  Typography,
  Button,
  InputLabel,
  FormControl,
  fade,
  InputBase,
  FormHelperText,
  IconButton
} from '@material-ui/core'
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import clsx from 'clsx'
import CopyToClipboard from "react-copy-to-clipboard";
import Api from '../../utils/http'
import common from '../../utils/common'
import Toast from "../../utils/Toast/index";

import { useTranslation, Trans, Translation } from 'react-i18next'

import { Decimal, Decimalish,Percent, LQTYStake, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import { useLiquity } from '../../hooks/LiquityContext'
import { shortenAddress } from '../../utils/shortenAddress'
import RedditTextField from '../../components/new-ui/RedditTextField'
import OverShadomBox from '../../components/new-ui/OverShadomBox'
import { AgentEarnings } from '../../components/new-ui/AgentEarnings'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      padding: '16px 16px 10px',
      fontSize: '18px',
      fontWeight: 500,
      color: '#745DDF',
      lineHeight: '25px',
    },
    margin: {
      // margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    inputRoot: {
      borderRadius: '4px',
      padding: '10px',
      border: '1px solid',
      borderColor:'#D2D6DC',
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
    h6: {
      marginLeft:'10px',
      fontSize: '20px',
      fontWeight: 600,
      color: '#293147',
      lineHeight: '24px'
    },
    p: {
      marginTop:'20px',
      marginBottom:'10px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '17px'
    }
  })
)

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

type state={
  domain: string;
  idx: number;
  url: string;
}

const selectFrontend = ({ ownFrontend }: LiquityStoreState) =>  ownFrontend ;

const SetDomain: React.FC<{ratio:string}> = ({
  ratio
}) => {
  let { t, i18n } = useTranslation();
  const classes = useStyles()
  const { account } = useLiquity()

  const frontend:any = useLiquitySelector(selectFrontend);
console.log(11111,frontend)
  let kickbackRate:any = 10;
  if (frontend && frontend.hasOwnProperty('kickbackRate')) {
     kickbackRate = Number(Decimal.ONE.sub(frontend.kickbackRate))*100;
  }

  const [value, setValue] = useState<state>({
    domain: '',
    idx: 0,
    url:''
  })

  const [initOpen, setInitOpen] = useState<boolean>(false)

  const [copied, setCopied] = useState<string>();

  const handleChange = (prop: any) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue({ ...value, [prop]: event.target.value })
  }

  const handleJudgmentInit = () => {
    const params = {
      address: account
    }
    Api.judgment_add(params).then((res) => {
      if (res.data.code === 200) {
          setValue(
            {
              ...value,
              idx:1,
              url:res.data.url
            }
          )
          setInitOpen(true)
      }
    }).catch((err) => {
      setInitOpen(true)
      console.log(err)
    })
  }

  useEffect(() => {
    handleJudgmentInit()
  },[])
  
  const handleClick = () => {
    setInitOpen(false)
    const params = {
      domain: value.domain,
      address: account
    }
    Api.create_site(common.getPostParams(params)).then((res) => {
      if (res.data.code === 200) {
        Toast.success(t('text_51'),t('text_58'), 2000, () => {
          handleJudgmentInit()
        })
      }
    }).catch((err) => {
      setInitOpen(true)
      Toast.error(t('text_59'), err, 2000, () => {
        console.log(err)
      })
    })
  }
  const text_url = `https://${value.url}`;
  
  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {value.idx === 0 ? t('text_61') : t('text_11')}
      </Typography>
      {
        value.idx === 0 ? (
          <>
            {/* <Box className={classes.boxForm}> */}
      
      <Typography variant="subtitle1" className={classes.p}>
      {t('text_12')}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormControl className={classes.margin}>
              <BootstrapInput value={value.domain} onChange={handleChange('domain')} id="bootstrap-input" />
          </FormControl>
        <Typography className={classes.h6} variant="h6">
        .liquity.fi
          </Typography>
      </Box>
      <FormHelperText component="div" className={classes.helperText}>
        <Typography variant="body2" gutterBottom>
          {/* Borrowing fee */}
        </Typography>
      </FormHelperText>

            <Button
              disabled={!initOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.subBtn,classes.btnColor)}
              style={{ width: '100%' }}
              onClick={handleClick}
              >
                {t('text_48')}
            </Button>
            </>
        ): (
            <>
              <Typography variant="subtitle1" className={classes.p}>
                  {t('text_12')}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.h6} variant="h6">
                {text_url}
                  </Typography>
                  <CopyToClipboard
                    text={text_url}
                  onCopy={() => {
                    setCopied(text_url);
                    Toast.success(t('text_51'),t('text_60'), 2000, () => {
                      console.log(text_url)
                    })
                  }
                  }
                  >
                    <IconButton color="primary" aria-label="add to shopping cart">
                      <FilterNoneIcon style={{ color: '#000', fontSize: '14px' }} />
                    </IconButton>
                  </CopyToClipboard>
              </Box>
              <Typography variant="subtitle1" className={classes.p}>
              {t('text_08')}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.h6} variant="h6">
                        { kickbackRate}%
                  </Typography>
              </Box>

              <AgentEarnings account={account}></AgentEarnings>
            </>
        )
      }
    </>
  )
};
export default SetDomain;
