import React from 'react'
// import clsx from 'clsx'
import {
  Box,
  AppBar,
  makeStyles,
  Theme,
  createStyles,
  Toolbar
} from '@material-ui/core'
import logo from '../../assets/logo.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      boxShadow: 'none',
      // zIndex:10000
    },
    background: {
      background: '#fff',
      minHeight: '64px',
      // paddingLeft: '10px',
      [theme.breakpoints.down('xs')]: {
        minHeight: '48px',
      },
    },
    logo: {
      height: '28px',
      [theme.breakpoints.down('xs')]: {
        height: '22px',
      },
    }
  })
)

export const WalletTopBar: React.FC = () => {
  const classes = useStyles()
  return (
    <>
       <AppBar className={classes.box}>
          <Toolbar className={classes.background}>
            <Box flexGrow={1} display="flex" justifyContent="flex-start" alignItems="center">
              <img src={logo} className={classes.logo} alt=""/>
            </Box>
          </Toolbar>
        </AppBar>
    </>
  )
}
