const en_US = {
  text_001:'Liquity Protocol',
  text_01:"Allows you to draw interest-free loans ",
  text_02: "against Ether used as collateral",
  text_03: "coming soon",
  text_04: "Liquity.fi provides frontend pages for Liquity Protocol.",
  text_05: "We'll return 100% LQTY rewards of LUSD stability pool to every user.",
  text_06: "Run my frontend",
  text_07: "My address",
  text_08: "Kickback ratio",
  text_09: "Before you proceed",
  text_10: "Set a domain",
  text_11: "My domain",
  text_12: "My domain",
  text_13: "1. What's the benefits of running a frontend for Liquity Protocol?",
  text_14: "The developers of the Liquity protocol announced that they will never open an official front-end and reward third-party operators through the LQTY. After the user stake LUSD from your front-end page to the stability pool, the smart contract will reward you with LQTY token, and you can set a kickback rate of LQTY to be returned to a user. Therefore, operating a third-party front-end and recommending it to others will be profitable.",
  text_15: "2. What business does Liquity.fi provide?",
  text_16: "Liqity.fi developed and optimized a set of full-featured front-end pages for the Liquity Protocol, which can help users use the protocol smoothly. At the same time, in order to help more non-developers earn LQTY through running a front-end, we have developed a one-click deployment function. You will immediately start earning LQTY rewards through your front-end. Currently, this feature is free.",
  text_17: "3. How do I set the kickback ratio?",
  text_18: "In order to prevent malicious competition, we limit the minimum percentage of users to 50%. Please set a reasonable ratio according to your own situation.",
  text_19: "4. How much LQTY rewards can I get and how to withdraw?",
  text_20: "How much you can get depends on how much LQTY your users have earned by staking LUSD, and you will get LQTY rewards according to the kickback ratio you set. You needn't claim it, it will be automatically sent to your front-end registered address.",
  text_21: "Run a frontend & Earn free LQTY token",
  text_23: "Liqity.fi developed and optimized a set of full-featured front-end pages for the Liquity Protocol, which can help users use the protocol smoothly. At the same time, in order to help more non-developers earn LQTY through running a front-end, we have developed a one-click deployment function. You will immediately start earning LQTY rewards through your front-end. Currently, this feature is free.",
  text_24: "Contact us",
  text_48: "Confirm",
  text_49: "Confirm",
  text_50: "Pending",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Broadcasting",
  text_58: "Your domain created successfully!",
  text_59: "Your domain created failed!",
  text_60: "Copied",
  text_61: "Set a domain",
  text_62: "You are about to register ",
  text_63: " to receive ",
  text_64: " of the LQTY rewards earned through this frontend. You will not be able to change the kickback rate for this address later. If you'd like to use a different kickback rate in the future, you will need to repeat this registration with a different address.",
  text_65: "Unpaid revenue",
  text_66: "Total LUSD in Stability Pool",
}
export default en_US;  