import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const POLLING_INTERVAL = 12000

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    1:'https://mainnet.infura.io/v3/3a4aa05a8a394ada85ed7bafda88107e',
    // 42: "https://kovan.infura.io/v3/ad9cef41c9c844a7b54d10be24d416e5",
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});
