import React, { useEffect, useState,useContext } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, Trans, Translation } from 'react-i18next'
import { TipContext } from "./hook/TipContext";
import loading from '../../assets/loading.svg'
import error from '../../assets/error.svg'
import Toast from "../../utils/Toast/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentBox: {
      textAlign:'center',
      padding: '0 20px 30px',
      backgroundColor: 'rgba(210, 214, 220, .2)'
    },
    paper: {
      width:'360px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      border: '2px solid #E3E3F3',
    },
    paper_width: {
      width:'500px',
    },
    circular_box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '76px',
      height: '76px',
      margin:'20px auto',
      backgroundColor: '#745DDF',
      borderRadius: '50%',
    },
    error_box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin:'20px auto',
    },
    loadingImg: {
      width: '24px',
      height: '24px',
      animation:'$turn 1s linear infinite'
    },
    error: {
      width: '60px',
      height: '52px',
    },
    "@keyframes turn": {
      "0%": {
        transform:'rotate(0deg)'
      },
      "25%": {
        transform:'rotate(90deg)'
      },
      "50%": {
        transform:'rotate(180deg)'
      },
      "75%": {
        transform:'rotate(270deg)'
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    },
    text: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#1F2533',
      lineHeight: '20px'
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
      background: '#1542CD',
      color: '#fff',
      '&:hover': {
        background: '#1542CD',
        color: '#fff',
      }
    }
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type typePorps = {
  type: any;
}

export const MonitorTip: React.FC<typePorps> = ({type}) => {
  let { t, i18n } = useTranslation();
  const classes = useStyles()
  const [open, setOpen] = React.useState(true);

  const Tip = useContext(TipContext);

  const handleClose = () => {
    setOpen(false)
  }

  Tip?.dispatchEvent(false)

  if (type === 'confirmed') {
    
    Toast.success(
      t('text_51'),
      t('text_52'),
      2000,
      () => {
      // console.log('操作成功')
      }
    )
    return null;
  } else if (type === 'failed') {
    Toast.error(
      t('text_53'),
      t('text_54'),
      2000,
      () => {
      // console.log('ok结束啦')
      }
    )
    return null;
  } else {
    return (
      <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        
          <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
            {
            type === 'waitingForConfirmation'
              ? t('text_50')
              : t('text_55')}
          </DialogTitle>
        <DialogContent className={classes.contentBox}>
          
            {
              type === 'waitingForConfirmation'
              ? (
                <>
                  <Box className={classes.circular_box}>
                    <img src={loading} className={classes.loadingImg} alt=""/>
                  </Box>
                </>
              )
              : (
                <>
                  <Box className={classes.error_box}>
                    <img src={error} className={classes.error} alt=""/>
                  </Box>
                </>
              )}
            
            <Typography variant="body2" className={classes.text}>
            {
            type === 'waitingForConfirmation'
              ? t('text_57')
              : t('text_56')}
            </Typography>
            {/* <Button className={classes.subBtn}> 关闭</Button> */}
          </DialogContent>
      </Dialog>
    )
  }
}

export const MonitorContextTip: React.FC = () => {
  let { t, i18n } = useTranslation();
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);

  const Tip = useContext(TipContext);

  const handleClose = () => {
    setOpen(false)
    Tip?.dispatchEvent(false)
  }

  useEffect(() => {
    setOpen(Tip?.status||false)
  },[Tip])

  return (
    <>
      <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper:classes.paper }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
          {t('text_49')}
        </DialogTitle>
        <DialogContent className={classes.contentBox}>
          <Box className={classes.circular_box}>
            <img src={loading} className={classes.loadingImg} alt=""/>
          </Box>
          <Typography variant="body2" className={classes.text}>
            {t('text_49')}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

type WalletType = {
  title: string;
  onCancel: () => void;
  // onRetry: () => void;
}

export const WalletTip: React.FC<WalletType> = ({
  title,
  onCancel,
  // onRetry,
  children
}) => {
  let { t, i18n } = useTranslation();
  const classes = useStyles()
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <>
      <Dialog
          open={open}
          onClose={handleClose}
        classes={{ paper: clsx(classes.paper,classes.paper_width) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
          {/* {t('text_49')} */}
          {title}
        </DialogTitle>
        <DialogContent className={classes.contentBox}>
          {/* <Box className={classes.circular_box}>
            <img src={loading} className={classes.loadingImg} alt=""/>
          </Box>
          <Typography variant="body2" className={classes.text}>
            {t('text_49')}
          </Typography> */}
          {children}
          {/* <Button className={classes.subBtn}> 关闭</Button> */}
        </DialogContent>
      </Dialog>
    </>
  )
}