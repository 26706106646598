import React from 'react';
import { withStyles, makeStyles, createStyles, Theme,Box } from '@material-ui/core';
import clsx from 'clsx';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import icon_02 from '../../assets/icon-02.svg';

import { useTranslation, Trans, Translation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '20px',
      height:'20px'
    },
    title: {
      textAlign: 'center',
      padding: '16px 16px 10px',
      fontSize: '18px',
      fontWeight: 500,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '25px',
    }
  })
)

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor:'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(210, 214, 220, 1)',
    marginBottom: -1,
    minHeight: 56,
    padding:'0',
    '&$expanded': {
      minHeight: 56,
      padding:'0',
    },
  },
  content: {
    margin: '18px 0',
    '&$expanded': {
      margin: '18px 0',
    },
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(45deg)',
    },
  },
  expanded: {
    
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 400,
    color: '#545A6C',
    lineHeight: '22px',
    paddingLeft: '0',
    paddingRight:'0'
  }
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {
  let { t, i18n } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box style={{padding:'0 20px'}}>
      <Typography variant="h3" className={classes.title}>
            FAQ
      </Typography>
      <Accordion
        style={{ marginTop: '12px' }}
        square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={ <img src={icon_02} className={clsx(classes.img)} alt=""/>}
          aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{t('text_13')}</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
        {t('text_14')}
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={ <img src={icon_02} className={clsx(classes.img)} alt=""/>}
          aria-controls="panel2d-content" id="panel2d-header">
          <Typography>{t('text_15')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography> */}
          {t('text_16')}
          {/* </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={ <img src={icon_02} className={clsx(classes.img)} alt=""/>}
          aria-controls="panel3d-content" id="panel3d-header">
          <Typography>{t('text_17')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {t('text_18')}
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={ <img src={icon_02} className={clsx(classes.img)} alt=""/>}
          aria-controls="panel3d-content" id="panel3d-header">
          <Typography>{t('text_19')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {t('text_20')}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}