
const zh_CN = {
  text_001:'Liquity 协议',
  text_01:"允许您用以太坊作抵押获得",
  text_02: "低至无息的贷款",
  text_03: "敬请期待",
  text_04: "Liquity.fi作为Liquity协议前端运营商，提供完整交互功能。",
  text_05: "稳定池的LQTY奖励，将100%返回至每位使用者。",
  text_06: "运营前端",
  text_07: "我的地址",
  text_08: "分成比例",
  text_09: "请仔细阅读",
  text_10: "设置域名",
  text_11: "我的代理域名",
  text_12: "我的域名",
  text_13: "1.为什么需要运营自己的前端页面？",
  text_14: "Liquity协议开发者宣布永久不会开设官方前端操作页面，并通过代币LQTY对第三方运营商进行奖励。当用户从你前端页面质押LUSD至稳定池后，合约会对你给予LQTY奖励，你可以设置将一定百分比的LQTY返还至某用户。因此运营一个第三方前端并推荐给他人使用将会有利可图。",
  text_15: "2.Liquity.fi提供的是什么业务？",
  text_16: "Liqity.fi是一个开放的智能合约接入平台。我们为Liquity协议开发并优化了一套全功能的前端页面，它可以帮助用户流畅的使用该协议。同时，为了帮助更多非开发者通过运营手段可以获得LQTY奖励，我们开发了一键部署的功能。你将立刻开始通过前端赚取LQTY奖金。当前这一功能是免费的。",
  text_17: "3.我该如何设置比例？",
  text_18: "为了防止恶意竞争，我们将用户获得的最小比例限制为50%。请根据自己的情况，设置一个合理的比例。",
  text_19: "4.我可以获得多少LQTY奖励如何提取？",
  text_20: "可以获得多少取决于你的用户通过质押LUSD获得了多少LQTY，你将会按照你设置的返点比例得到相应的奖励。该奖励无需提取，它会自动发送到你的前端注册地址内。为了防止恶意竞争，我们将用户获得的最小比例限制为50%。请根据自己的情况，设置一个合理的比例。",
  text_21: "运营Liquity前端，赚取LQTY",
  text_23: "Liqity.fi是一个开放的智能合约接入平台。我们为Liquity协议开发并优化了一套全功能的前端页面，它可以帮助用户流畅的使用该协议。其次，为了帮助更多非开发者可以获得LQTY奖励，我们开发了一键部署的功能。所有人都可以立刻获得一个自己的前端页面。这一切都免费。",
  text_24: "联系我们",
  text_48: "确认",
  text_49: "确认交易",
  text_50: "待处理交易",
  text_51: "成功",
  text_52: "交易确认",
  text_53: "失败",
  text_54: "请再次尝试",
  text_55: "错误",
  text_56: "交易被拒绝",
  text_57: "交易广播中",
  text_58: "域名绑定成功",
  text_59: "域名绑定失败",
  text_60: "复制成功",
  text_61: "绑定域名",
  text_62: "你正在使用 ",
  text_63: " 注册为前端运营商，你将会收到来自你的用户使用稳定池质押LUSD收益的 ",
  text_64: " LQTY返点收益。一旦提交后，此返点率不可被修改。如果你想使用一个新的返点率，你不得不使用一个新的地址重新设置后再次提交。",
  text_65: "未支付收益",
  text_66: "LUSD质押总额",
}
export default zh_CN;